import React from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

const MenuCard = ({ list = [], width }) => (
  <Flex
    column
    css={`
      width: ${width};
      margin: 20px 0;
    `}
  >
    {list.map(({ title, price }, index) => (
      <Flex
        justifyBetween
        key={title}
        wrap="true"
        css={`
          border-bottom: ${(list.length - 1 !== index && 'solid 1px #ccc') || 'inherit'};
          margin: 5px 0;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 5px;
        `}
      >
        <Flex column>
          {title.split('\n').map(line => (
            <div key={line}>{line}</div>
          ))}
        </Flex>
        <FlexItem grow={1}>
          <Flex justifyEnd>
            <div>{price}</div>
          </Flex>
        </FlexItem>
      </Flex>
    ))}
  </Flex>
);

export default MenuCard;
