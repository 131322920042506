import React from 'react';
import { graphql } from 'gatsby';
import Flex from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import ButtonList from '../components/ButtonList';
import { H3, H4, H5 } from '../components/common/H.style';
import DepartmentSchedule from '../components/DepartmentSchedule';
import Card from '../components/Card';
import MenuCard from '../components/MenuCard';

import ka1lg from '../assets/img/ka-1-lg.png';

const KanmoArticleList = ({ list = [] }) => (
  <Flex column>
    {list.map(({ id, date, title, path }, index) => (
      <Flex
        key={id}
        css={`
          height: 60px;
          margin: 5px 0;
          border-bottom: ${(list.length - 1 !== index && 'solid 1px #ccc') || 'inherit'};
        `}
      >
        <Flex center>
          <span
            css={`
              font-size: 12px;
              color: #666666;
              margin-right: 30px;
            `}
          >
            {date}
          </span>
        </Flex>
        <Flex center>
          <Link to={path}>
            <span
              css={`
                font-size: 16px;
                color: #3b4043;
                overflow-wrap: anywhere;
              `}
            >
              {title}
            </span>
          </Link>
        </Flex>
      </Flex>
    ))}
  </Flex>
);

const Kampo = ({ data: { allMarkdownRemark, allFile } }) => {
  const { size } = useTrackedState();
  const kampoArticles = allMarkdownRemark.edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map(
      ({
        node: {
          id,
          excerpt,
          frontmatter: { date, title, path },
        },
      }) => ({
        id,
        excerpt,
        date,
        title,
        path,
      })
    );
  const monsinPdf = allFile.edges.find(({ node: { extension } }) => extension === 'pdf');
  const monsinDocx = allFile.edges.find(({ node: { extension } }) => extension === 'docx');
  return (
    <SubLayout topTitle="漢方外来・鍼灸治療">
      <Img src={ka1lg} />
      <Flex
        justifyAround
        wrap="true"
        css={`
          margin: 20px 0;
        `}
      >
        <DepartmentSchedule
          list={[
            { day: '火曜日', time: '17:00～20:00' },
            { day: '金曜日※', time: '17:00〜20:00' },
          ]}
          requiredReservation={true}
          size={size}
        />
      </Flex>
      <P
        css={`
          text-align: center;
          margin-bottom: 60px;
        `}
      >
        ※ 金曜日は灸、鍼、耳つぼのみとなります。
      </P>
      <P>
        初めて受診される場合、こちらの問診票にあらかじめ記入していただければ幸いです。
        <br />
        （お持ちいただけない場合は、来院後にご記入いただきます。）
      </P>
      <ButtonList
        size={size}
        list={[
          { link: monsinPdf.node.publicURL, external: true, title: '問診票ダウンロード(PDF形式)' },
          {
            link: monsinDocx.node.publicURL,
            external: true,
            title: '問診票ダウンロード(Word形式)',
          },
        ]}
      />
      <P>
        当院では、鍼灸師による問診、舌・脈などの状態（証）のチェックの後、医師が漢方薬を処方する、という漢方外来を行っています。
        処方はすべて保険適応のエキス剤ですので、非常に安価に治療を受けられます。
        漢方を試してみたいときは、ぜひ当院に一度ご相談ください。
      </P>
      <Card>
        <P
          css={`
            color: #d30000;
          `}
        >
          ※注意事項
          <br />
          初回は必ず採血にて状態をチェックいたします。
          副作用をチェックする際のベースとなる採血ですので、ご了承ください。
          採血の料金は３割負担で2000円程度です。
        </P>
      </Card>

      <H3>鍼灸治療メニュー</H3>
      <Flex
        column
        center
        css={`
          margin-bottom: 40px;
        `}
      >
        <MenuCard
          width={(size === 'sm' && '100%') || '50%'}
          list={[
            { title: 'お灸治療(15分)', price: '3,300円（税込）' },
            { title: '置き鍼治療(15分)', price: '3,300円（税込）' },
            { title: '鍼治療(30分)', price: '5,500円（税込）' },
            { title: '鍼治療(60分)', price: '11,000円（税込）' },
            { title: '耳つぼダイエット(10分)', price: '1,650円（税込）' },
          ]}
        />
      </Flex>
      <H4>漢方に関するQ&A</H4>
      <H5>漢方薬と西洋薬とでは、どこが違うのですか？</H5>
      <P>
        西洋薬（新薬）は、人工的に化学合成された物質でその多くは一つの成分で構成され、強い薬理作用を示しますが、漢方薬は二種類以上の天然生薬で構成され、穏やかな薬理作用を示します。
      </P>
      <H5>漢方薬は保険がきかないのでしょうか？</H5>
      <P>
        エキス剤と呼ばれる『粉薬』は一部保険対象ですが、『生薬』については原則自費扱いになります。
      </P>
      <H5>漢方薬には副作用がないのでしょうか？</H5>
      <P>
        残念ながらそうとは言えません。
        漢方薬は基本的に薬用植物を主体とした生薬を原材料としていますが自然のものだからと言って安全とは言えません。
        但し、薬を調節することによって問題は解決します。
      </P>
      <H4>漢方に関するコラムなど</H4>
      <KanmoArticleList list={kampoArticles} />
    </SubLayout>
  );
};

export default Kampo;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/kampo-articles/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
    allFile(filter: { name: { regex: "/monsin-kampo/" }, extension: { regex: "/docx|pdf/" } }) {
      edges {
        node {
          publicURL
          extension
        }
      }
    }
  }
`;
